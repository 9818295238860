import Dynamsoft from "dynamsoft-javascript-barcode";
Dynamsoft.BarcodeReader.engineResourcePath =
  "https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@7.3.0-v2/dist/";
// Please visit https://www.dynamsoft.com/CustomerPortal/Portal/TrialLicense.aspx to get a trial license
Dynamsoft.BarcodeReader.productKeys =
  // "t0068NQAAAKM7Aa4g3/mJAl54+C0TeYozmdZL4oS4p7M2ApF0D8EFiwf9DevJK98Co5VbDrHy01ywpPatsFAdU/duHpb9LS0=";
  "t0068MgAAAJ+MQnm7tldRWw/XPoV+Lp3PK/HpndNPOTiw6kBQdSPAUa90WtrQENBlvS9oWuFtHTAouQUlWxjKchhHdnqIK80=";
// "t0068NQAAAJ/hHnmbV0xQPZAPMXAuF8LVisrOl5jXTqch+9Qn4oPdfZvSaEkcbP8wq/mB7v7uE4WBH7Ms7LlPTT811LC1K4M=";
// Dynamsoft.BarcodeReader._bUseFullFeature = true; // Control of loading min wasm or full wasm.
export default Dynamsoft;
