import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import LaunchTwoToneIcon from "@material-ui/icons/LaunchTwoTone";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { getEnvironmentURL } from "../_helpers";

export default function HudsonPaperworkPage(props) {
  const isDev = process.env.NODE_ENV === "development";
  const testVins = "1FTFW1E86NKD12638";
  const classes = useStyles();
  const [uploadType, setUploadType] = useState("text");
  const [text, setText] = useState(isDev ? testVins : null);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);

  const handleAddFile = (file = null, text = null) => {
    setLoading(true);
    let formData = new FormData();
    if (file) {
      formData.append("file", file);
    } else if (text) {
      formData.append("text", text);
    }

    let url = `${getEnvironmentURL()}/api/reports/hudsonPaperworkCheck`;

    if (uploadType === "file" || uploadType === "text") {
      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then(
          (response) => response.json() // if the response is a JSON object
        )
        .then((data) => {
          if (Array.isArray(data)) {
            setResults(data);
            setLoading(false);
          } else {
            setLoading(false);
            setResults(null);
            alert("Unexpected data");
          }
        })
        .catch((error) => {
          setLoading(false);
          setResults(null);
          alert(error);
        });
    }
  };

  return (
    <PageWithAppDrawer2 pageTitle="CS Paperwork Download">
      <Paper className={classes.paper}>
        <Typography variant="h6" color="primary">
          CS Paperwork Download
        </Typography>
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          {!results && !loading ? (
            <>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => setUploadType("text")}
                >
                  Paste VINS
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  setUploadType("text");
                  setResults(null);
                }}
              >
                Start Over
              </Button>
            </Grid>
          )}
        </Grid>

        {loading && <CircularProgress />}
        {!loading && !results && (
          <>
            {uploadType === "text" && (
              <>
                <TextField
                  multiline
                  rows="10"
                  fullWidth
                  variant="outlined"
                  defaultValue={isDev ? testVins : null}
                  onChange={(e) => setText(e.target.value)}
                />
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => handleAddFile(null, text)}
                >
                  Send Text VINs
                </Button>
              </>
            )}
          </>
        )}
        {!loading && results && (
          <>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography style={{ fontWeight: "bold" }}>VIN</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: "bold" }}>
                  VIN Valid
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontWeight: "bold" }}>Status</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography style={{ fontWeight: "bold" }}>Download</Typography>
              </Grid>
              {results.map((item, index) => {
                const hasErrors = item.errors.length > 0;
                const bgColor = hasErrors ? "#FFAAAA" : "inherit";
                let isHoland = false;
                let linkStamped7501 = null;
                let linkSignedSoc = null;
                let linkHs7 = null;
                let linkBondReleaseIndividual = null;
                if (item.vehicle) {
                  item.vehicle.images.map((image) => {
                    if (image.docnote === "7501 Scanned") {
                      linkStamped7501 = image.cloud_url_orig;
                    }
                    if (image.docnote === "baySignedSoc") {
                      linkSignedSoc = image.cloud_url_orig;
                    }
                    if (image.docnote === "Bay DOT HS-7") {
                      linkHs7 = image.cloud_url_orig;
                    }
                    if (image.docnote === "Bond Release Individual") {
                      linkBondReleaseIndividual = `${getEnvironmentURL()}/api/vehicles/${
                        item.vehicle.vehicleid
                      }/multipleForms/0?docs=30&download&downloadName=${
                        item.vehicle.vehiclevin
                      }-bond-release-individual.pdf`;
                    }
                    return null;
                  });
                  isHoland =
                    item.vehicle?.client?.clientname
                      ?.toLowerCase()
                      .includes("holand") || false;
                  if (isHoland) {
                    linkSignedSoc = null;
                    linkHs7 = null;
                    linkBondReleaseIndividual = null;
                  }
                }
                return (
                  <Fragment key={index}>
                    <Grid
                      item
                      xs={3}
                      style={{
                        borderBottom: "1px solid black",
                      }}
                    >
                      {item.vehicle ? (
                        <>
                          <RouterLink
                            to={`/vehicles/${item.vehicle?.vehicleid}`}
                            target="_blank"
                          >
                            {item.vin}{" "}
                            <LaunchTwoToneIcon
                              style={{ fontSize: 12 }}
                              color="primary"
                            />
                          </RouterLink>
                          <br />
                          {item.vehicle.client?.clientname}
                        </>
                      ) : (
                        item.vin
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{
                        borderBottom: "1px solid black",
                      }}
                    >
                      {item.validVin ? "VIN Valid" : "VIN Invalid"}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        borderBottom: "1px solid black",
                      }}
                    >
                      {item.successes.length > 0 && (
                        <>
                          {item.successes.map((error, index) => (
                            <p style={{ color: "blue", fontWeight: "bold" }}>
                              {error}
                            </p>
                          ))}
                        </>
                      )}
                      {item.errors.length > 0 && (
                        <>
                          {item.errors.map((error, index) => (
                            <p style={{ color: "red" }}>{error}</p>
                          ))}
                        </>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      style={{
                        backgroundColor: bgColor,
                        borderBottom: "1px solid black",
                      }}
                    >
                      {isHoland && <Typography>Holand Vehicle</Typography>}
                      {isHoland && (
                        <>
                          {item.vehicle && (
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              // target="_blank"
                              href={`${getEnvironmentURL()}/api/vehicles/${
                                item.vehicle.vehicleid
                              }/multipleForms/0?docs=12&download&downloadName=${
                                item.vehicle.vehiclevin
                              }-all.pdf`}
                              disabled={!linkStamped7501}
                            >
                              {!linkStamped7501
                                ? "Requirements not met"
                                : "Download Stamped 7501"}
                            </Button>
                          )}
                        </>
                      )}
                      {!isHoland && (
                        <>
                          {item.vehicle && (
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              // target="_blank"
                              href={`${getEnvironmentURL()}/api/vehicles/${
                                item.vehicle.vehicleid
                              }/multipleForms/0?docs=21,12,24,15&download&downloadName=${
                                item.vehicle.vehiclevin
                              }-all.pdf`}
                              disabled={hasErrors}
                            >
                              {hasErrors
                                ? "Requirements not met"
                                : "Download All"}
                            </Button>
                          )}
                          <br />
                          {item.vehicle && hasErrors && (
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              // target="_blank"
                              href={`${getEnvironmentURL()}/api/vehicles/${
                                item.vehicle.vehicleid
                              }/multipleForms/0?docs=21,12,24,15&download&downloadName=${
                                item.vehicle.vehiclevin
                              }-partial.pdf`}
                            >
                              Download Partial
                            </Button>
                          )}
                          <br />
                          {linkBondReleaseIndividual && (
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              target="_blank"
                              href={linkBondReleaseIndividual}
                            >
                              Bond Release Individual
                            </Button>
                          )}
                          <br />
                          {linkStamped7501 && (
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              target="_blank"
                              href={linkStamped7501}
                            >
                              7501 Stamped
                            </Button>
                          )}
                          <br />
                          {linkSignedSoc && (
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              target="_blank"
                              href={linkSignedSoc}
                            >
                              Signed SOC
                            </Button>
                          )}
                          <br />
                          {linkHs7 && (
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              target="_blank"
                              href={linkHs7}
                            >
                              HS-7
                            </Button>
                          )}
                        </>
                      )}
                    </Grid>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}
      </Paper>
    </PageWithAppDrawer2>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));
